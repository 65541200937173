import React, { Fragment, useState } from 'react'
import { NextRouter, useRouter } from 'next/router'
import i18nextConfig from '../../next-i18next.config'
import { Transition, Menu } from '@headlessui/react'
import { HeroIcons } from '../lib/utils/heroIcons'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import {
  languageFlagMapping,
  languageLabelMapping,
} from '../constants/languageMapping'
import useLocale from '../hooks/useLocale'

export default function LanguageSelect() {
  const router: NextRouter = useRouter()
  const { locale, setLocale } = useLocale()
  //const { i18n } = useTranslation()

  const supportedLngs: string[] = i18nextConfig.i18n.locales
  // const currentLocale = router.locale || i18nextConfig.i18n.defaultLocale
  //
  // const [selected, setSelected] = useState<string>(currentLocale)
  //
  // const onLanguageChange = async (newLocale: string) => {
  //   await i18n.changeLanguage(newLocale)
  //   setSelected(newLocale)
  // }

  return (
    <div className="w-28 cursor-pointer">
      <Menu>
        <div className="relative">
          <Menu.Button className="relative flex w-full cursor-default items-center rounded-2xl border border-neutralGray/50 px-2 py-1 text-sm text-neutralBlack shadow-2xl hover:cursor-pointer">
            <div className="pt-0.5">{languageFlagMapping[locale]}</div>
            <div className="ml-auto text-neutralBlack">
              {languageLabelMapping[locale]}
            </div>
            <span className="pointer-events-none ml-auto">
              <HeroIcons.ChevronDownIcon
                className="h-5 w-5 text-neutralBlack/50"
                aria-hidden="true"
              />
            </span>
          </Menu.Button>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Menu.Items className="absolute top-9 z-40 mt-1 max-h-60 w-full overflow-y-scroll rounded-md border border-neutralGray/50 bg-neutralWhite text-sm shadow-lg">
              {supportedLngs.map((lng) => (
                <Menu.Item key={lng} as={Fragment}>
                  <Link
                    href={router.asPath}
                    locale={lng}
                    onClick={() => setLocale(lng)}
                    className="flex items-center px-3 py-0.5"
                  >
                    <span className="pr-3 pt-1 text-neutralBlack">
                      {languageFlagMapping[lng]}
                    </span>
                    <span
                      className={`block ${
                        locale === lng ? 'font-medium' : 'font-normal'
                      }`}
                    >
                      {languageLabelMapping[lng]}
                    </span>
                  </Link>
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </div>
      </Menu>
    </div>
  )
}
