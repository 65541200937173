import { NavigationItem } from '../../types/Navigation'
import routes from '../../config/routes'

const isRouteActive = (item: NavigationItem, currentPath: string): boolean => {
  let baseCurrentUrl: string
  let itemBaseUrl: string

  if (currentPath === routes.index) {
    baseCurrentUrl = `/`
  } else if (currentPath.split('/')[1]) {
    baseCurrentUrl = `/${currentPath.split('/')[1]}/`
  } else {
    baseCurrentUrl = '/'
  }

  const itemHrefParts = item.href.split('/')
  if (itemHrefParts.length < 2 || !itemHrefParts[1]) {
    itemBaseUrl = routes.index
  } else {
    itemBaseUrl = `/${itemHrefParts[1]}/`
  }

  return baseCurrentUrl === itemBaseUrl
}

export default isRouteActive
