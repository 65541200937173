interface Props {
  className?: string
}

export function LogoIcon({ className }: Props) {
  return (
    <svg
      width="46"
      height="52"
      viewBox="0 0 46 52"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M41.5261 10.6775V1.73644C41.5261 0.776683 40.7425 0 39.7743 0H34.8097C33.8414 0 33.0578 0.776683 33.0578 1.73644V5.79553L24.3657 0.785929C23.5168 0.297728 22.4739 0.281085 21.6101 0.743396L1.54478 11.5134C0.593284 12.0238 0 13.0113 0 14.0838V20.8761C0 21.7434 0.479478 22.5423 1.24813 22.9565L11.4347 28.4488C12.2052 28.863 13.1418 28.3119 13.1418 27.4428V15.9997L22.7108 11.0862L44.3769 23.2062C45.1026 23.6112 46 23.0934 46 22.2667V14.6701C46 13.7954 45.5317 12.9872 44.7687 12.5471L41.528 10.6794L41.5261 10.6775Z" />
      <path d="M33.0877 35.7659L23 41.5929L1.46269 29.1826C0.813433 28.809 0 29.2732 0 30.0166V38.2772C0 39.2665 0.541045 40.1782 1.41418 40.6609L21.1007 51.5178C22.2799 52.1687 23.7164 52.1595 24.8862 51.4956L44.4049 40.4408C45.3899 39.8823 46 38.843 46 37.7169V32.0859C46 31.2519 45.5485 30.4826 44.819 30.0684L34.4739 24.2081C33.8563 23.8586 33.0877 24.3006 33.0877 25.0052V35.7641V35.7659Z" />
    </svg>
  )
}
