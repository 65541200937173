import React, { useEffect } from 'react'
import { ToastAlertType } from '../types/ToastAlert'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { diplayAlert } from '../lib/utils/diplayAlert'
import removeQueryParams from '../lib/utils/removeQueryParams'

const AlertConsumer = () => {
  const router = useRouter()
  const { alert } = router.query
  const { t } = useTranslation('common')

  const sendAlert = (alert: ToastAlertType) => {
    switch (alert) {
      case ToastAlertType.MISSING_KYC:
        diplayAlert(t('alerts.missingKyc'))
        break
      case ToastAlertType.MISSING_WALLET:
        diplayAlert(t('alerts.missingWallet'))
        break
      case ToastAlertType.ERROR_LINK_PAYPAL_ACCOUNT:
        diplayAlert(t('alerts.errorLinkPaypalAccount'))
        break
      default:
        break
    }
  }

  useEffect(() => {
    if (alert !== undefined) {
      sendAlert(alert as ToastAlertType)
      removeQueryParams(router, ['alert'])
    }
  }, [alert])

  return <></>
}

export default AlertConsumer
