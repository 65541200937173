import React from 'react'
import { HeroIcons } from '../lib/utils/heroIcons'
import routes from '../config/routes'
import { useRouter } from 'next/router'
import { classNames } from '../lib/utils/classNames'
import Button, { buttonHierarchy, buttonShape } from './Button'
import LanguageSelect from './LanguageSelect'
import CurrencySelect from './CurrencySelect'
import { useTranslation } from 'next-i18next'
import { useAuth } from '../providers/AuthProvider'
import { NavigationItem } from '../types/Navigation'
import Link from 'next/link'
import isRouteActive from '../lib/utils/isRouteActive'

interface MobileBarProps {
  navigation: NavigationItem[]
  mobilebarOpen: boolean
  setMobilebarOpen: (setOpen: boolean) => void
}

export default function Mobilebar({
  navigation,
  mobilebarOpen,
  setMobilebarOpen,
}: MobileBarProps) {
  const { t } = useTranslation('common')
  const router = useRouter()
  const { isLoggedIn, logout, isLoading } = useAuth()

  const handleOpenMenu = () => {
    setMobilebarOpen(!mobilebarOpen)
  }

  const handleLogout = async (): Promise<void> => {
    await logout()
    setMobilebarOpen(false)
    router.push(routes.login)
  }

  const openMenuContent = () => {
    return (
      <div className="w-full pb-3">
        <div className="space-y-2 px-3">
          <div>
            <div className="pb-1 text-sm font-semibold text-neutralBlack">
              {t('topbar.globalSettings.language')}
            </div>
            <LanguageSelect />
          </div>

          <div>
            <div className="flex items-center">
              <div className="pb-1 text-center text-sm font-semibold text-neutralBlack">
                {t('topbar.globalSettings.currency')}
              </div>
            </div>
            <CurrencySelect />
          </div>
        </div>

        {/*<ul className="flex items-center rounded-md transition-all duration-150">*/}
        {/*  <Link href="" className="flex w-full">*/}
        {/*    <HeroIcons.MoonIcon*/}
        {/*      className="mx-auto h-8 w-8 shrink-0 text-neutralBlack/50 hover:text-brandLightBlue"*/}
        {/*      aria-hidden="true"*/}
        {/*    />*/}
        {/*  </Link>*/}
        {/*</ul>*/}
      </div>
    )
  }

  return (
    <div
      className={classNames(
        mobilebarOpen
          ? 'absolute top-0 z-50 h-full w-full bg-neutralGray/10 backdrop-blur-md transition-opacity'
          : '',
        'transition-all duration-1000',
      )}
    >
      <div
        className={classNames(
          mobilebarOpen ? 'absolute bottom-0 pt-3' : 'pt-0.5',
          'border-1 w-full rounded-t-xl border-neutralGray/50 bg-neutralWhite',
        )}
      >
        <div>
          <div
            className={classNames(
              mobilebarOpen ? 'grid-rows-[auto,1fr]' : '',
              'grid h-full grid-cols-5 ',
            )}
          >
            {mobilebarOpen && (
              <div className="col-span-6">{openMenuContent()}</div>
            )}

            {!mobilebarOpen &&
              navigation.map((item) => (
                <div key={item.name} className="flex items-center">
                  <Link
                    href={item.href}
                    className={classNames(
                      isRouteActive(item, router.asPath)
                        ? 'bg-supportingBlue/20 text-brandLightBlue'
                        : 'text-neutralBlack/50',
                      'mx-auto rounded-full p-2',
                    )}
                  >
                    <item.icon className="mx-auto h-7 w-auto" />
                  </Link>
                </div>
              ))}

            {mobilebarOpen && isLoggedIn && (
              <div className="col-start-1 col-end-5 flex items-center">
                <button
                  onClick={handleLogout}
                  className="mx-auto flex w-full rounded-full p-2 text-neutralBlack/50 hover:text-brandLightBlue"
                >
                  <div className="flex items-center space-x-1">
                    <HeroIcons.ArrowRightOnRectangleIcon
                      className="mx-auto h-7 w-auto "
                      aria-hidden="true"
                    />
                    <div className="font-medium">{t('navigation.logout')}</div>
                  </div>
                </button>
              </div>
            )}

            {mobilebarOpen && !isLoading && !isLoggedIn && (
              <div className="col-start-1 col-end-5 flex items-center">
                <Link
                  href={routes.login}
                  className="mx-auto flex w-full rounded-full p-2 text-neutralBlack/50 hover:text-brandLightBlue"
                >
                  <div className="flex items-center space-x-1">
                    <HeroIcons.ArrowRightOnRectangleIcon
                      className="mx-auto h-7 w-auto "
                      aria-hidden="true"
                    />
                    <div className="font-medium">{t('navigation.login')}</div>
                  </div>
                </Link>
              </div>
            )}

            <div className="col-start-5 col-end-5 flex items-center">
              <Button
                hierarchy={buttonHierarchy.custom}
                shape={buttonShape.pill}
                className={classNames(
                  mobilebarOpen
                    ? 'bg-supportingBlue/20 text-brandLightBlue'
                    : 'text-neutralBlack/50',
                  'mx-auto rounded-full p-2',
                )}
                onClick={() => handleOpenMenu()}
              >
                <HeroIcons.Cog6ToothIcon className="mx-auto h-7 w-auto" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
