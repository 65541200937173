import React, { useState } from 'react'
import { useAuth } from '../providers/AuthProvider'
import { useTranslation } from 'next-i18next'
import Button, { buttonHierarchy, buttonShape } from './Button'
import { HeroIcons } from '../lib/utils/heroIcons'
import routes from '../config/routes'
import LanguageSelect from './LanguageSelect'
import CurrencySelect from './CurrencySelect'
import { Popover } from '@headlessui/react'

type TopbarProps = {
  component?: any
}

export default function Topbar({ component }: TopbarProps) {
  const { t } = useTranslation('common')

  const { isLoggedIn, isLoading } = useAuth()

  const [_selectedLang, _setSelectedLang] = useState('en')
  const [_selectedCurrency, _setSelectedCurrency] = useState('USD')

  return (
    <>
      <div className="flex items-center py-3">
        <div>{component}</div>

        <div className="flex flex-1 justify-end">
          {!isLoading && !isLoggedIn && (
            <Button
              hierarchy={buttonHierarchy.primary}
              shape={buttonShape.custom}
              className="animate-all relative mr-2 flex items-center rounded-2xl p-3"
              link={routes.login}
            >
              {t('topbar.login')}
              <HeroIcons.ArrowRightOnRectangleIcon className="ml-2 h-5 w-5" />
            </Button>
          )}

          <Popover className="relative flex">
            <Popover.Button className="focus:outline-none">
              <div className="relative rounded-full border border-neutralGray/50 bg-neutralWhite p-3">
                <HeroIcons.Cog6ToothIcon className="h-5 w-5 text-neutralBlack/50 hover:text-brandLightBlue" />
              </div>
            </Popover.Button>

            <Popover.Panel className="absolute right-0 top-full z-30 mt-2 w-72 rounded-2xl border border-neutralGray/50 bg-neutralWhite p-3 shadow-lg backdrop-blur-lg">
              <div>
                <p className="mb-4 text-xl font-bold text-neutralBlack">
                  {t('topbar.globalSettings.title')}
                </p>

                <ul className="-mx-5 -my-2 select-none">
                  <li className="flex cursor-pointer items-center justify-between bg-white/5 px-5 py-2">
                    <div className="font-semibold text-neutralBlack">
                      <div>{t('topbar.globalSettings.language')}</div>
                    </div>
                    <div>
                      <div className="flex items-center gap-1">
                        <LanguageSelect />
                      </div>
                    </div>
                  </li>
                  <li className="flex cursor-pointer items-center justify-between px-5 py-2">
                    <div className="font-semibold text-neutralBlack">
                      <div className="flex items-center gap-1">
                        <div>{t('topbar.globalSettings.currency')}</div>
                      </div>
                    </div>
                    <CurrencySelect />
                  </li>
                </ul>
              </div>
            </Popover.Panel>
          </Popover>

          {/*{isLoggedIn && (*/}
          {/*  <Popover className="relative flex ml-2">*/}
          {/*    <Popover.Button className="focus:outline-none">*/}
          {/*      <Card className="relative border border-neutralGray/50 bg-neutralWhite p-3">*/}
          {/*        <HeroIcons.BellIcon className="h-5 w-5 text-neutralBlack/50 hover:text-brandLightBlue" />*/}
          {/*      </Card>*/}
          {/*    </Popover.Button>*/}

          {/*    <Popover.Panel className="absolute right-0 top-full z-30 mt-2 w-72 rounded-2xl border border-neutralGray/50 bg-neutralWhite p-3 shadow-lg backdrop-blur-lg">*/}
          {/*      <div>*/}
          {/*        <p className="mb-4 text-xl font-bold text-neutralBlack">*/}
          {/*          {t('topbar.notifications.title')}*/}
          {/*        </p>*/}
          {/*        <p className="text-neutralBlack">*/}
          {/*          {t('topbar.notifications.noNotification')}*/}
          {/*        </p>*/}
          {/*      </div>*/}
          {/*    </Popover.Panel>*/}
          {/*  </Popover>*/}
          {/*)}*/}
        </div>
      </div>
    </>
  )
}
