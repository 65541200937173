import React from 'react'
import { classNames } from '../lib/utils/classNames'
import { HeroIcons } from '../lib/utils/heroIcons'
import routes from '../config/routes'
import Link from 'next/link'
import { LogoFullIcon } from './icons/LogoFullIcon'
import { useRouter } from 'next/router'
import Button, { buttonHierarchy, buttonShape } from './Button'
import { LogoIcon } from './icons/LogoIcon'
import { useAuth } from '../providers/AuthProvider'
import { NavigationItem } from '../types/Navigation'
import isRouteActive from '../lib/utils/isRouteActive'

interface SidebarProps {
  sidebarState: boolean
  setSidebarState: React.Dispatch<React.SetStateAction<any>>
  navigation: NavigationItem[]
}

export default function Sidebar({
  setSidebarState,
  sidebarState,
  navigation,
}: SidebarProps) {
  const router = useRouter()
  const { isLoggedIn, logout } = useAuth()

  const handleSidebarState = () => {
    setSidebarState(!sidebarState)
  }

  const arrowButton = (state: boolean) => {
    switch (state) {
      case true:
        return (
          <HeroIcons.ChevronLeftIcon className="mx-auto h-10 w-10 text-neutralBlack/40 hover:cursor-pointer hover:text-brandLightBlue" />
        )
      case false:
        return (
          <HeroIcons.ChevronRightIcon className="mx-auto h-10 w-10 text-neutralBlack/40 hover:cursor-pointer hover:text-brandLightBlue" />
        )
      default:
        return (
          <HeroIcons.ChevronLeftIcon className="mx-auto h-10 w-10 text-neutralBlack/40 hover:cursor-pointer hover:text-brandLightBlue" />
        )
    }
  }

  return (
    <div className="relative z-10 flex h-screen">
      <Button
        hierarchy={buttonHierarchy.custom}
        shape={buttonShape.pill}
        className="absolute -right-6 top-1/2 hidden h-12 w-12 items-center rounded-full border border-neutralGray/50 bg-neutralWhite shadow-2xl xl:flex"
        onClick={() => handleSidebarState()}
      >
        {arrowButton(sidebarState)}
      </Button>

      <div className="flex grow flex-col gap-y-10 overflow-y-auto border-r border-neutralGray/50 bg-neutralWhite px-6 py-6">
        <div className="relative flex h-16 shrink-0 items-center pb-4">
          <Link
            href={routes.index}
            className="mx-auto flex h-full w-full transition-colors duration-200 focus:outline-none"
          >
            <LogoFullIcon
              className={classNames(
                sidebarState ? '' : 'hidden',
                'mx-auto fill-brandLightBlue',
              )}
              width="272"
              height="52"
            />

            <LogoIcon
              className={classNames(
                sidebarState ? 'hidden' : '',
                'mx-auto items-center fill-brandLightBlue',
              )}
            />
          </Link>
        </div>
        <nav className="flex flex-1 flex-col">
          <ul role="list" className="flex flex-1 flex-col">
            <li>
              <ul role="list" className="space-y-4">
                {navigation.map((item) => (
                  <li key={item.name}>
                    <Link
                      href={item.href}
                      className={classNames(
                        isRouteActive(item, router.asPath)
                          ? 'bg-neutralGray/10 text-brandLightBlue'
                          : 'text-neutralBlack/50 hover:text-brandLightBlue',
                        'group flex w-full items-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 hover:bg-neutralGray/10',
                      )}
                    >
                      <item.icon
                        className={classNames(
                          isRouteActive(item, router.asPath)
                            ? 'text-brandLightBlue'
                            : 'text-neutralBlack/50 group-hover:text-brandLightBlue',
                          'h-9 w-9 shrink-0',
                          sidebarState ? '' : 'mx-auto',
                        )}
                        aria-hidden="true"
                      />
                      <div
                        className={classNames(
                          sidebarState ? '' : 'hidden',
                          'text-md mt-0.5 transition-all',
                        )}
                      >
                        {item.name}
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>

            <li
              className={classNames(
                sidebarState ? 'flex items-center' : 'space-y-8',
                'mt-auto',
              )}
            >
              {/*<ul className="flex items-center rounded-md transition-all duration-150">*/}
              {/*  <Link href="" className="flex w-full">*/}
              {/*    <HeroIcons.MoonIcon*/}
              {/*      className="mx-auto h-8 w-8 shrink-0 text-neutralBlack/50 hover:text-brandLightBlue"*/}
              {/*      aria-hidden="true"*/}
              {/*    />*/}
              {/*  </Link>*/}
              {/*</ul>*/}
              {isLoggedIn && (
                <ul className="items-center rounded-md transition-all duration-150">
                  <Button
                    hierarchy={buttonHierarchy.custom}
                    shape={buttonShape.pill}
                    onClick={logout}
                    className="flex w-full"
                  >
                    <HeroIcons.ArrowRightOnRectangleIcon
                      className="mx-auto h-8 w-8 shrink-0 text-neutralBlack/50 hover:text-indigo-600"
                      aria-hidden="true"
                    />
                  </Button>
                </ul>
              )}
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}
