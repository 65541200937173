interface Props {
  className?: string
  width?: string
  height?: string
}

export function LogoFullIcon({ className, width, height }: Props) {
  return (
    <svg
      fill="white"
      width={width ? width : '1899.26'}
      height={height ? height : '291.37'}
      viewBox="0 0 1899.26 291.37"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g>
        <path d="M222.58,64.86V16.51c0-5.19-4.2-9.39-9.39-9.39h-26.61c-5.19,0-9.39,4.2-9.39,9.39v21.95l-46.59-27.09c-4.55-2.64-10.14-2.73-14.77-.23L8.28,69.38c-5.1,2.76-8.28,8.1-8.28,13.9v36.73c0,4.69,2.57,9.01,6.69,11.25l54.6,29.7c4.13,2.24,9.15-.74,9.15-5.44v-61.88l51.29-26.57,116.13,65.54c3.89,2.19,8.7-.61,8.7-5.08v-41.08c0-4.73-2.51-9.1-6.6-11.48l-17.37-10.1Z" />
        <path d="M177.35,200.53l-54.07,31.51L7.84,164.93c-3.48-2.02-7.84,.49-7.84,4.51v44.67c0,5.35,2.9,10.28,7.58,12.89l105.52,58.71c6.32,3.52,14.02,3.47,20.29-.12l104.62-59.78c5.28-3.02,8.55-8.64,8.55-14.73v-30.45c0-4.51-2.42-8.67-6.33-10.91l-55.45-31.69c-3.31-1.89-7.43,.5-7.43,4.31v58.18Z" />
      </g>
      <g>
        <path d="M379.51,176.74c9.71,0,19.43,.27,29.12-.1,5.77-.22,7.97,1.92,8.41,7.63,1.53,19.87,11.23,34.03,30.51,40.41,15.38,5.09,31.03,6.01,46.16-1,14.22-6.59,16.94-24.54,4.07-33.35-10.01-6.86-21.96-11.09-33.4-15.6-21.9-8.64-44.91-14.9-65.91-25.28-47.83-23.62-49.23-83.12-22.11-114.88C394.93,12.84,419.46,3.73,446.95,.84c23.26-2.44,45.86,.33,67.37,9.78,21.56,9.47,38.23,24.27,48.76,45.68,2.08,4.24,1.48,6.5-2.67,8.55-16.94,8.37-33.84,16.82-50.68,25.41-4.49,2.29-6.2,.17-8.28-3.5-11.41-20.08-35.32-29.03-54.97-21.05-6.96,2.83-11.23,7.68-11.83,15.06-.63,7.79,2.1,14.87,9.58,17.88,16.65,6.69,33.71,12.34,50.47,18.78,16.53,6.35,33.65,11.75,49.15,20.07,31.12,16.71,41.58,45.66,33.57,81.21-7.39,32.8-29.75,52.37-60.07,63.07-47.71,16.83-93.3,11.85-134.75-17.95-21.65-15.56-35.1-37.14-39.11-63.99-.7-4.67-1.86-9.29-2.18-13.98-.56-8.26,.3-9.06,8.39-9.12,9.93-.08,19.87-.02,29.8-.02Z" />
        <path d="M675.04,100.75c8.76-6.45,16.62-14.13,25.97-18.8,40.93-20.4,103.22,3.08,103.75,62.37,.4,44.69-.02,89.38,.2,134.08,.03,5.9-1.85,8-7.68,7.94-19.86-.2-39.73-.2-59.59,0-5.91,.06-7.72-2.28-7.69-8.07,.21-35.89,.08-71.78,.16-107.67,.04-15.95-8.08-27-21.11-28.32-17.65-1.79-30.29,6.99-33.26,23.3-.72,3.96-1.15,8.03-1.16,12.05-.09,33.86-.18,67.72,.08,101.57,.04,5.45-1.74,7.14-6.85,7.1-20.09-.13-40.18-.14-60.27,.02-4.9,.04-6.59-1.64-6.58-6.7,.13-89.61,.13-179.22,0-268.83,0-5.73,2.19-7.34,7.59-7.28,19.18,.22,38.37,.29,57.56-.03,6.37-.1,8.64,1.76,8.55,8.43-.34,27.08-.16,54.17-.12,81.26,0,2.82,.32,5.64,.44,7.58Z" />
        <path d="M1385.06,190.83c-2.56,18.22,12.73,37.94,32.43,40.06,6.46,.7,13.12,.66,19.59,0,10.54-1.09,19.19-5.66,25.03-15.15,1.34-2.17,4.86-4.18,7.42-4.24,16.69-.38,33.4-.14,50.1-.23,4.94-.03,7.05,1.67,5.96,6.92-4.49,21.72-15.72,39.26-33.53,52.15-26.66,19.31-56.51,23.93-88.67,18.57-26.84-4.47-49.16-16.27-65.13-38.32-18.71-25.84-24.43-55.13-20.13-86.54,2.95-21.56,11.22-40.74,26.14-56.72,15.78-16.89,35.54-26.34,58.14-30.43,24.33-4.41,47.96-2.38,70.83,7.08,24.67,10.21,40.32,28.88,49.24,53.56,6.74,18.67,6,38.13,5.59,57.53-.08,3.57-1.64,5.24-5.43,5.18-31.13-.46-62.3-.01-93.38-1.54-14.84-.73-29.5-5.15-44.2-7.89Zm6.45-42c21.02,10.94,43.23,12.6,65.99,12.24-.04-18.53-12.47-30.01-24.98-33-17.88-4.27-33.05,3.14-41,20.76Z" />
        <path d="M891.83,190.83c-2.54,18.26,12.74,37.95,32.43,40.07,6.46,.69,13.12,.66,19.59,0,10.54-1.09,19.2-5.66,25.02-15.15,1.33-2.17,4.86-4.17,7.42-4.23,16.69-.38,33.4-.14,50.1-.23,4.94-.03,7.05,1.68,5.96,6.93-4.5,21.72-15.73,39.25-33.54,52.15-26.66,19.31-56.52,23.91-88.68,18.59-22.61-3.74-42.09-12.99-57.84-29.48-15.22-15.94-23.16-35.4-26.87-57.09-4.45-26.06-1.33-50.84,10.89-74.09,15.55-29.59,41.01-45.61,73.53-51.43,24.13-4.32,47.51-2.19,70.16,7.15,24.7,10.18,40.3,28.89,49.23,53.56,6.76,18.67,5.98,38.13,5.59,57.53-.07,3.58-1.63,5.24-5.42,5.18-31.13-.46-62.3-.01-93.38-1.54-14.84-.73-29.5-5.15-44.21-7.9Zm72.02-29.7c1.45-17.78-12.54-30.36-24.61-33.11-18.3-4.17-34.59,3.99-40.56,20.96,20.5,10.84,42.65,12.28,65.17,12.14Z" />
        <path d="M1884.81,105.32c-12.72,14.96-24.07,28.34-35.47,41.67-2.94,3.43-5.03,.77-7.3-1.16-13.35-11.43-28.77-17.66-46.4-17.19-5.47,.15-11.32,1.35-16.18,3.73-3.16,1.55-5.91,5.78-6.97,9.37-1.56,5.31,2,9.34,6.88,10.9,10.01,3.21,20.13,6.25,30.4,8.51,20.4,4.49,40.76,8.31,60.32,16.62,40.15,17.06,35.73,72.77,5.74,92.67-20.25,13.44-42.72,19.06-66.32,20.32-30.32,1.63-59.97-2.37-86.79-17.77-20.56-11.81-34.56-28.69-36.12-53.7-.36-5.71,1.27-8.19,7.43-8.09,18.29,.29,36.58,.16,54.87,.06,4.94-.03,7.99,1.11,8.51,6.97,.72,8.13,6.16,13.53,13.08,16.94,15.75,7.76,32.21,7.55,48.67,2.65,4.69-1.4,9.15-3.88,9.2-10.24,.05-6.44-4.5-8.93-9.19-10.12-11.97-3.05-24.09-5.61-36.26-7.78-19.35-3.45-38.39-7.52-55.69-17.55-15.7-9.1-23.93-22.53-25.37-40.53-1.48-18.46,1.26-35.62,14.48-49.67,10.67-11.33,24.26-17.93,38.97-21.92,34.98-9.49,69.26-6.67,102.21,8.24,9.28,4.2,17.46,10.83,27.31,17.08Z" />
        <path d="M1185.9,132.64c-11.13,0-21.5-.17-31.85,.07-5.15,.12-7.42-1.77-7.38-7.02,.12-13.55,.05-27.09-.05-40.64-.04-5.06,2.06-7.38,7.3-7.28,10.35,.19,20.71,.06,31.9,.06,0-8.87,0-17.56,0-26.24,0-6.77,.08-13.55,0-20.32-.05-4.22,1.64-5.99,6.07-5.95,20.77,.16,41.55,.14,62.32,.01,4.17-.03,5.47,1.61,5.41,5.65-.18,12.87-.09,25.74-.06,38.61,.02,7.79,.25,7.82,8.25,6.41,10.85-1.91,21.72-3.76,32.64-5.17,1.66-.21,4.81,1.73,5.3,3.29,4.53,14.37,8.55,28.9,12.88,43.34,1.06,3.53-.16,5.1-3.5,5.68-13.7,2.38-27.33,5.5-41.13,6.98-14.38,1.54-14.46,.85-14.46,14.95,0,17.39-.13,34.78,.08,52.16,.06,5.15,.54,10.42,1.74,15.41,1.73,7.16,6.94,11.86,13.97,13.18,8.12,1.52,16.47,2.02,24.75,2.46,4.52,.24,6.36,1.63,6.28,6.42-.24,15.35-.15,30.71-.04,46.06,.02,3.18-.59,5.35-4.25,5.36-25.42,.04-51.06,2.2-75.54-7.36-21.84-8.53-36.07-24.67-37.96-47.6-2.61-31.6-1.95-63.47-2.69-95.23-.02-.87,0-1.73,0-3.28Z" />
        <path d="M1130.06,146.12c0,44.01-.12,88.01,.14,132.02,.04,6.12-1.49,8.34-7.78,8.24-19.63-.3-39.27-.2-58.9-.05-5.01,.04-7.05-1.36-7.04-6.92,.17-89.37,.17-178.73,.02-268.1,0-5.9,1.85-7.9,7.78-7.81,19.4,.29,38.82,.28,58.22,0,5.97-.08,7.69,2.03,7.67,7.89-.2,44.91-.11,89.82-.11,134.72Z" />
        <path d="M1623.1,106.15c6.64-10.37,14.24-19.71,25.76-24.96,12.69-5.79,26.13-6.38,39.69-5.45,1.45,.1,3.85,3.17,3.87,4.89,.28,20.3,.3,40.62-.03,60.92-.02,1.48-3.14,4.02-4.98,4.18-9.62,.84-19.34,.7-28.96,1.6-19.74,1.85-28.71,13.04-32.81,28.96-1.61,6.25-2.2,12.91-2.25,19.39-.24,27.98-.23,55.96,.02,83.94,.05,5.13-1.71,6.72-6.53,6.69-20.31-.12-40.62-.11-60.93,0-4.51,.02-6.22-1.48-6.21-6.29,.14-64.99,.14-129.98,.02-194.96,0-5.14,1.51-7.35,6.91-7.29,18.95,.19,37.91,.19,56.86,0,5.47-.06,7.78,1.79,7.47,7.47-.37,6.86-.09,13.75-.09,20.63,.73,.1,1.46,.2,2.19,.31Z" />
      </g>
    </svg>
  )
}
